<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="12" cols="12">
                  <v-autocomplete
                    v-model="search"
                    :items="search_items"
                    :loading="isLoading"
                    @keyup.enter="searching($event.target.value)"
                    hide-no-data
                    item-text="name"
                    item-value="id"
                    label="Search"
                    placeholder="Press Enter to Search "
                    :prepend-icon="icons.mdiAccountSearchOutline"
                    @change="get_search_items_info"
                  ></v-autocomplete>
                </v-col>
                <v-col md="2" cols="12">
                  <v-text-field
                    v-model="id_no"
                    label="ID #"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-select
                    v-model="position"
                    dense
                    outlined
                    label="Position"
                    :items="['EMPLOYEE', 'ADMIN','AR/AP', 'TREASURY', 'ACCOUNTING', 'AMD','PROPERTY CUSTODIAN','HUMAN RESOURCE','BOOKKEEPING','CMD','EXECUTIVE','AUDIT','IT','CHAPEL AUDIT','FINANCE']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_new_user" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-tabs
              v-model="tab1"
              show-arrows
            >
              <v-tab
                v-for="tab in tabs1"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
              <v-tab-item>
                <v-data-table dense
                              :headers="headers"
                              :items="list_of_users_ho_active"
                              hide-default-footer
                              disable-pagination
                              class="mt-3"
                >
                  <template v-slot:top>
                    LEGENDS -
                    (Logout-
                    <v-icon
                      class="mr-2"
                      color="error"
                    >
                      {{icons.mdiLogoutVariant}}
                    </v-icon>
                    ),
                    (Deactivate-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{icons.mdiAccountOff}}
                    </v-icon>
                    ),
                    (Activate-
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      {{icons.mdiAccountCheck}}
                    </v-icon>
                    )
                  </template>
                  <template v-slot:item.name="{ item }">
                    <tr>
                      <td class="text-center">
                        {{item.employee.last_name+', '
                        +item.employee.first_name+' '
                        +item.employee.middle_name}}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting && item.is_login===1"
                            class="mr-2"
                            color="error"
                            @click="logout_user(item.id)"
                          >
                            {{icons.mdiLogoutVariant}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active===1"
                            class="mr-2"
                            color="warning"
                            @click="change_status(item.id,0)"
                          >
                            {{icons.mdiAccountOff}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active!=1"
                            class="mr-2"
                            color="info"
                            @click="change_status(item.id,1)"
                          >
                            {{icons.mdiAccountCheck}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action2="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="reset_password(item.id)"
                          >
                            {{icons.mdiLockReset}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action3="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="change_possssition(item.id)"
                          >
                            {{icons.mdiSwapHorizontal}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table dense
                              :headers="headers"
                              :items="list_of_users_ho_inactive"
                              hide-default-footer
                              disable-pagination
                              class="mt-3"
                >
                  <template v-slot:top>
                    LEGENDS -
                    (Logout-
                    <v-icon
                      class="mr-2"
                      color="error"
                    >
                      {{icons.mdiLogoutVariant}}
                    </v-icon>
                    ),
                    (Deactivate-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{icons.mdiAccountOff}}
                    </v-icon>
                    ),
                    (Activate-
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      {{icons.mdiAccountCheck}}
                    </v-icon>
                    )
                  </template>
                  <template v-slot:item.name="{ item }">
                    <tr>
                      <td class="text-center">
                        {{item.employee.last_name+', '
                        +item.employee.first_name+' '
                        +item.employee.middle_name}}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting && item.is_login===1"
                            class="mr-2"
                            color="error"
                            @click="logout_user(item.id)"
                          >
                            {{icons.mdiLogoutVariant}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active===1"
                            class="mr-2"
                            color="warning"
                            @click="change_status(item.id,0)"
                          >
                            {{icons.mdiAccountOff}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active!=1"
                            class="mr-2"
                            color="info"
                            @click="change_status(item.id,1)"
                          >
                            {{icons.mdiAccountCheck}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action2="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="reset_password(item.id)"
                          >
                            {{icons.mdiLockReset}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action3="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="change_possssition(item.id)"
                          >
                            {{icons.mdiSwapHorizontal}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
          <v-tab-item>
            <v-tabs
              v-model="tab1"
              show-arrows
            >
              <v-tab
                v-for="tab in tabs1"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
              <v-tab-item>
                <v-data-table dense
                              :headers="headers"
                              :items="list_of_users_employee_active"
                              hide-default-footer
                              disable-pagination
                              class="mt-3"
                >
                  <template v-slot:top>
                    LEGENDS -
                    (Logout-
                    <v-icon
                      class="mr-2"
                      color="error"
                    >
                      {{icons.mdiLogoutVariant}}
                    </v-icon>
                    ),
                    (Deactivate-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{icons.mdiAccountOff}}
                    </v-icon>
                    ),
                    (Activate-
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      {{icons.mdiAccountCheck}}
                    </v-icon>
                    )
                  </template>
                  <template v-slot:item.name="{ item }">
                    <tr>
                      <td class="text-center">
                        {{item.employee.last_name+', '
                        +item.employee.first_name+' '
                        +item.employee.middle_name}}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting && item.is_login===1"
                            class="mr-2"
                            color="error"
                            @click="logout_user(item.id)"
                          >
                            {{icons.mdiLogoutVariant}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active===1"
                            class="mr-2"
                            color="warning"
                            @click="change_status(item.id,0)"
                          >
                            {{icons.mdiAccountOff}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active!=1"
                            class="mr-2"
                            color="info"
                            @click="change_status(item.id,1)"
                          >
                            {{icons.mdiAccountCheck}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action2="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="reset_password(item.id)"
                          >
                            {{icons.mdiLockReset}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action3="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="change_possssition(item.id)"
                          >
                            {{icons.mdiSwapHorizontal}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table dense
                              :headers="headers"
                              :items="list_of_users_employee_inactive"
                              hide-default-footer
                              disable-pagination
                              class="mt-3"
                >
                  <template v-slot:top>
                    LEGENDS -
                    (Logout-
                    <v-icon
                      class="mr-2"
                      color="error"
                    >
                      {{icons.mdiLogoutVariant}}
                    </v-icon>
                    ),
                    (Deactivate-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{icons.mdiAccountOff}}
                    </v-icon>
                    ),
                    (Activate-
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      {{icons.mdiAccountCheck}}
                    </v-icon>
                    )
                  </template>
                  <template v-slot:item.name="{ item }">
                    <tr>
                      <td class="text-center">
                        {{item.employee.last_name+', '
                        +item.employee.first_name+' '
                        +item.employee.middle_name}}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting && item.is_login===1"
                            class="mr-2"
                            color="error"
                            @click="logout_user(item.id)"
                          >
                            {{icons.mdiLogoutVariant}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active===1"
                            class="mr-2"
                            color="warning"
                            @click="change_status(item.id,0)"
                          >
                            {{icons.mdiAccountOff}}
                          </v-icon>
                          <v-icon
                            v-if="!is_deleting && item.is_active!=1"
                            class="mr-2"
                            color="info"
                            @click="change_status(item.id,1)"
                          >
                            {{icons.mdiAccountCheck}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action2="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="reset_password(item.id)"
                          >
                            {{icons.mdiLockReset}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.action3="{ item }">
                    <tr>
                      <td class="text-center">
                        <div>
                          <v-icon
                            v-if="!is_deleting"
                            class="mr-2"
                            color="info"
                            @click="change_possssition(item.id)"
                          >
                            {{icons.mdiSwapHorizontal}}
                          </v-icon>
                          <v-progress-circular color="info" indeterminate
                                               v-if="is_deleting"></v-progress-circular>
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="30%">
      <v-card>
        <v-form ref="form2" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">CHANGE POSITION</h4>
            </v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="align-center mx-2 mt-5">
            <v-flex xs12 md12>
              <v-select
                v-model="position"
                dense
                outlined
                label="Position"
                :items="['EMPLOYEE', 'ADMIN','AR/AP', 'TREASURY', 'ACCOUNTING', 'AMD','PROPERTY CUSTODIAN','HUMAN RESOURCE','BOOKKEEPING','CMD','EXECUTIVE','AUDIT','IT','CHAPEL AUDIT','FINANCE']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                class="w-full"
                color="primary"
                @click="done_request"
                v-if="!saving"
              >
                Done
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="is_change_salary = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiLogoutVariant,
    mdiAccountOff,
    mdiAccountCheck,
    mdiLockReset,
    mdiSwapHorizontal,
    mdiGoogleCirclesCommunities,
    mdiAccountOutline,
    mdiCharity,
    mdiGift,
    mdiHandshakeOutline,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      alert_message: '',

      id_no: '',
      name: '',
      position: '',
      employee_id: '',

      selected_id: '',
      search: '',
      search_member: '',
      search_items: [],

      list_of_users_ho_active: [],
      list_of_users_ho_inactive: [],
      list_of_users_employee_active: [],
      list_of_users_employee_inactive: [],

      is_change_salary: false,
      isLoading: false,
      is_deleting: false,
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Employee ID', value: 'employee_id', sortable: false},
        {text: 'ID #', value: 'id_no', sortable: false},
        {text: 'NAME', value: 'name', sortable: false},
        {text: 'Position', value: 'position', sortable: false},
        {text: 'Branch', value: 'employee.branch.branch_code', sortable: false},
        {text: 'Login Time', value: 'log_in_time', sortable: false},
        {text: 'Status', value: 'status', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
        {text: 'Reset', value: 'action2', sortable: false},
        {text: 'Change Position', value: 'action3', sortable: false},
      ],
      tab: '',
      tabs: [
        {title: 'HEAD OFFICE', icon: mdiGoogleCirclesCommunities},
        {title: 'EMPLOYEEE', icon: mdiAccountOutline},
      ],
      tab1: '',
      tabs1: [
        {title: 'ACTIVE', icon: mdiAccountOutline},
        {title: 'DEACTIVATED', icon: mdiGoogleCirclesCommunities},
      ],
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiLogoutVariant,
          mdiAccountOff,
          mdiAccountCheck,
          mdiLockReset,
          mdiSwapHorizontal,
        },
      }
    },
    mounted() {
      this.list_of_user()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['register_credentials', 'get_all_users', 'force_logout_user', 'change_status_of_user', 'reset_password_of_user', 'change_position']),
      ...mapActions('employee', ['search_all_active_employee',]),

      list_of_user() {
        this.get_all_users()
          .then(response => {
            this.list_of_users_ho_active = response.data[0].ho_active
            this.list_of_users_ho_inactive = response.data[0].ho_inactive
            this.list_of_users_employee_active = response.data[0].emp_active
            this.list_of_users_employee_inactive = response.data[0].emp_inactive
          })
          .catch(error => {
            console.log(error)
          })
      },

      reset() {
        Object.assign(this.$data, initialState())
        this.list_of_user()
      },
      searching(value) {
        this.credit_to = ''
        this.payment_for = ''
        this.isLoading = true
        this.search_items = []

        this.search_all_active_employee({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
            this.search = ''
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.employee_id = this.search_items[index].id
            this.id_no = this.search_items[index].id_no
            this.name = this.search_items[index].name
          } else {
            this.employee_id = ''
            this.id_no = ''
            this.name = ''
            this.search_items = []
          }
        } else {
          this.employee_id = ''
          this.id_no = ''
          this.name = ''
          this.search_items = []
        }
      },
      save_new_user() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('employee_id', this.employee_id)
          data.append('id_no', this.id_no)
          data.append('name', this.name)
          data.append('position', this.position)
          this.register_credentials(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      logout_user(value) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', value)
        this.force_logout_user(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      change_status(value, status) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', value)
        data.append('status', status)
        this.change_status_of_user(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      reset_password(value) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', value)
        this.reset_password_of_user(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.is_deleting = false
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      change_possssition(value) {
        this.selected_id = value
        this.is_change_salary = true
      },
      done_request() {
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('id', this.selected_id)
          data.append('position', this.position)
          this.change_position(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
